/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context, useContentFactory, UseContentFactoryParams } from '@vue-storefront/core';

import { ContentPosition, ContentType } from '../types';

const params: UseContentFactoryParams<ContentType, ContentPosition> = {
  search: async (context: Context, params: any) => {
    const { ...searchParams } = params;
    if (params.ContentType) {
      if (params.ContentType === 'updatePreferences') {
        return await context.$shopify.api.updateNewsLetterPreferences(searchParams);
      } else if (params.ContentType === 'page') {
        return await context.$shopify.api.getPages(searchParams);
      }
    }
    return await context.$shopify.api.getBlogPosts(searchParams);
  },
};

const useContent = useContentFactory<ContentType, ContentPosition>(params);

export default useContent;
